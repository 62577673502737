// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import LocalStorageBackend from 'i18next-localstorage-backend';

// import ptBr from './locales/pt-br';
// import en from './locales/en';

// i18n
//   .use(LocalStorageBackend) // Backend para armazenar traduções no localStorage
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     resources: {
//       en: en,
//       'pt-BR': ptBr,
//     },
//     fallbackLng: 'en', // Idioma de fallback se o detectado não estiver disponível
//     debug: true, // Ativa logs para desenvolvimento

//     backend: {
//       // Opções para localStorage
//       expirationTime: 7 * 24 * 60 * 60 * 1000, // Cache de uma semana
//     },

//     detection: {
//       // Opções para o detector de idioma
//       order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
//       caches: ['localStorage'], // Armazenar o idioma detectado no localStorage
//     },

//     interpolation: {
//       escapeValue: false, // React já escapa valores automaticamente
//     },

//     ns: ['login', 'common'],
//   });

// export default i18n;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocalStorageBackend from 'i18next-localstorage-backend';
import axios from 'axios'; // Para fazer a requisição à API
import api from './services/api';
import ptBr from './locales/pt-br';
import en from './locales/en';

const apiLanguageDetector = {
  name: 'apiDetector',

  async lookup() {
    try {
      const response = await api.get('/v1/adm/language');
      return response.data.language;
    } catch (error) {
      console.error(
        'Erro ao obter idioma da API, tentando localStorage/navegador:',
        error
      );
      return null;
    }
  },

  cacheUserLanguage(lng) {
    localStorage.setItem('i18nextLng', lng);
  },
};

i18n
  .use(LocalStorageBackend) // Armazenamento local para cache de traduções
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
        useSuspense: false
    },
    resources: {
      en: en,
      'pt-BR': ptBr,
    },
    fallbackLng: 'en', // Idioma fallback

    debug: true, // Ativa logs

    backend: {
      expirationTime: 7 * 24 * 60 * 60 * 1000, // Cache por uma semana
    },

    detection: {
      order: ['apiDetector', 'localStorage', 'navigator', 'htmlTag'], // Ordem personalizada de detecção
      caches: ['localStorage'], // Cache apenas no localStorage
    },

    interpolation: {
      escapeValue: false, // React já faz isso automaticamente
    },

    ns: ['login', 'common'], // Namespaces
  });

i18n.services.languageDetector.addDetector(apiLanguageDetector);

export default i18n;
