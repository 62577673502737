import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import queryStringModule from "query-string";
import defaultConfig from "../../config/default";
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as ReportActions } from "../../store/ducks/reports";

// functions
import formatDate from "../../functions/formatDate";

import { constants } from "../../config";
import showCustomerReferenceIdOnTables from "../../functions/showCustomerReferenceIdOnTables";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: '#1b301b',
    marginLeft: theme.spacing(2.5)
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const DEFAULT_QUERY = {
  withUpdates: true,
  withOpening: true,
  withSupplies: true
};

class DeliveryPaginationTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 5
  };

  t = this.props.t;

  handleChangePage = (event, newPage) => {
    const { reportsRequest, reports } = this.props;

    const parsed = queryStringModule.parse(reports.queryString);
    parsed.page = newPage + 1;

    const stringfied = queryStringModule.stringify({
      ...parsed,
      DEFAULT_QUERY
    });

    reportsRequest(stringfied);
  };

  handleChangeRowsPerPage = event => {
    const { reportsRequest, reports, handleChangeReports } = this.props;
    const rowsPerPage = parseInt(event.target.value);

    this.setState({ rowsPerPage });

    const parsed = queryStringModule.parse(reports.queryString);
    parsed.perPage = rowsPerPage;

    const stringfied = queryStringModule.stringify({
      ...parsed,
      ...DEFAULT_QUERY
    });
    reportsRequest(stringfied);
    handleChangeReports("queryString", stringfied);
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));
  openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("mySidenav").style.top = "200px";
  };
  checkIfExistsSubTeam = () => {
    const { delivery } = this.props;

    if (!delivery || !delivery.data || !delivery.data.length) return false;

    for (let i = 0; i < delivery.data.length; i++) {
      if (delivery.data[i].subTeam) return true;
    }
    return false;
  };
  render() {
    const classes = this.useStyles2();
    const { delivery } = this.props;
    const { emptyRows } = this.state;
    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className="tableheader">
              <TableRow align="center">
                <TableCell
                  className="tablecell-header th-delivery"
                  align="center"
                >
                  <p style={{color: "#006660", fontSize: "18px", margin: 0}}>
                    {this.t("table.delivery")}
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header th-delivery"
                  align="center"
                >
                  <p style={{color: "#006660", fontSize: "18px", margin: 0}}>
                    {this.t("table.access_code")}
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header th-delivery"
                  align="center"
                >
                  <p style={{color: "#006660", fontSize: "18px", margin: 0}}>
                    {this.t("table.user")}
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header th-delivery"
                  align="center"
                >
                  <p style={{color: "#006660", fontSize: "18px", margin: 0}}>
                    {this.t("table.main_unit")}
                  </p>
                </TableCell>

                <TableCell
                  className="tablecell-header tablecell-header__initial-table"
                  align="center"
                >
                  <p style={{color: "#006660", fontSize: "18px", margin: 0}}>
                    {this.t("table.created_at")}
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header th-delivery"
                  align="center"
                >
                  <p style={{color: "#006660", fontSize: "18px", margin: 0}}>
                    {this.t("table.locker")}
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header tablecell-header__team-compart-deliverytable"
                  align="center"
                >
                  <p style={{color: "#006660", fontSize: "18px", margin: 0}}>
                    {this.t("table.door")}
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header tablecell-header__team-status-deliverytable"
                  align="center"
                >
                  <p style={{color: "#006660", fontSize: "18px", margin: 0}}>
                    {this.t("table.status")}
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {delivery.data &&
                delivery.data.map(
                  row =>
                  row.locker && (
                    <TableRow
                    align="center"
                        key={row.id}
                        id="table-row-reports"
                        className={[
                          "table-item-reports",
                          this.state.current === row.id &&
                            "table-item-reports-active"
                        ]}
                        onClick={() => {
                          // this.setState({ current: row.id });
                          // this.openNav();
                        }}
                      >
                        <TableCell
                          className="tablecell td-delivery"
                          align="center"
                        >
                          {showCustomerReferenceIdOnTables(
                            row.customerReferenceId
                          )}
                        </TableCell>
                        <TableCell
                          className="tablecell td-delivery"
                          align="center"
                        >
                          {row.secretCode}
                        </TableCell>
                        <TableCell
                          className="tablecell td-delivery"
                          align="center"
                        >
                          {row.business ? (
                            <>
                              {row.business.name} <br /> {row.business.email}
                            </>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell
                          className="tablecell td-delivery"
                          align="center"
                        >
                          {row.subTeam
                            ? `${row.subTeam.identifierCode} - ${
                                row.subTeam.name
                              }`
                            : "-"}
                        </TableCell>
                        <TableCell
                          className="tablecell tablecell-header__initial-table"
                          align="center"
                        >
                          {formatDate(row.created_at)}
                        </TableCell>
                        <TableCell
                          className="tablecell td-delivery"
                          align="center"
                        >
                          {row.locker.address}
                        </TableCell>
                        <TableCell
                          className="tablecell tablecell-header__team-status"
                          align="center"
                        >
                          {row.compartment.compartmentNumber}
                        </TableCell>
                        <TableCell
                          className="tablecell tablecell-header__team-status-deliverytable"
                          align="center"
                        >
                          {constants.deliveryStatusTable()[row.status]}
                        </TableCell>
                      </TableRow>
                    )
                )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={defaultConfig.rowsPerPageOptions}
            count={delivery.total}
            rowsPerPage={delivery.perPage}
            page={delivery.page - 1}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true
            }}
            labelRowsPerPage= {this.t("table.lines_per_page")}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to === -1 ? count : to} ${this.t("table.of")} ${count}`
            }
            component="div"
          />
        </div>
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  delivery: state.reports.deliveries,
  reports: state.reports
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ReportActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("reports")(DeliveryPaginationTable));
