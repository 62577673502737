import React from "react";
import { useSelector } from "react-redux";
import ReactExport from "react-export-excel";

import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import formatDate, { getDate, getHours } from "../../functions/formatDate";
import sortArrayObjectByDate from "../../functions/sortArrayObjectByDate";
import { colors } from "../../styles";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function ExportExcelExtract({ data, classes }) {
  const { t } = useTranslation("reports");
  const dataset =
    data &&
    data.map(d => {
      d.date = getDate(d.createdAt);
      d.hour = getHours(d.createdAt);
      d.address = d.locker.address;
      d.compartmentNumber = d.compartment.compartmentNumber;
      d.fullname = `${d.toUser.name.slice(0, 1) + "***"} ${
        d.toUser.lastName ? d.toUser.lastName.slice(0, 1) + "***" : ""
      }`;
      d.handover_fee = d.fee ? d.fee.handover_fee : "?";
      d.licensed_revenue = d.fee ? d.fee.licensed_revenue : "?";
      d.used_bonus = d.fee ? d.fee.used_bonus : "?";
      d.used_wallet = d.fee ? d.fee.used_wallet : "?";
      d.balance_due = d.fee ? d.fee.balance_due : "?";
      return d;
    });
  return (
    <ExcelFile
      element={
        <Button
          className={classes.button}
          disabled={!data.length}
          variant="contained"
          margin="normal"
          style={{
            backgroundColor: "#006660",
            color: "#fff",
            textTransform: "lowercase",
            fontSize: 14,
            height: 35
          }}
        >
          { t("filters.export")}
          {/* Exportar para .xlsx */}
        </Button>
      }
    >
      <ExcelSheet data={dataset} name="Extrato de receitas">
        <ExcelColumn label="DATA INICIAL" value="date" />
        <ExcelColumn label="HORA INICIAL" value="hour" />
        <ExcelColumn label="TEMPO ALUGADO" value="boughtHours" />
        <ExcelColumn label="LOCKER" value="address" />
        <ExcelColumn label="PORTA" value="compartmentNumber" />
        <ExcelColumn label="USUÁRIO" value="fullname" />
        <ExcelColumn label="TOTAL" value="totalCost" />
        <ExcelColumn label="BONUS USADO" value="used_bonus" />
        <ExcelColumn label="CARTEIRA USADA" value="used_wallet" />
        <ExcelColumn label="VALOR DEVEDOR PELO CLIENTE" value="balance_due" />
        <ExcelColumn label="TAXA" value="handover_fee" />
        <ExcelColumn label="RECEITA" value="licensed_revenue" />
      </ExcelSheet>
    </ExcelFile>
  );
}

function getDeliveriesWithAllFields(data) {
  let dataset = [];
  let opening = [];
  let updates = [];
  let supplies = [];
  let deliveries = [];

  if (!data || !data.length) return dataset;

  try {
    data.map(delivery => {
      try {
        delivery.address = delivery.locker.address;
        delivery.compartmentNumber = delivery.compartment.compartmentNumber;
        delivery.user = delivery.business ? delivery.business.name : "";

        delivery.collectorType = delivery.collectorType || "";
        delivery.collectorDocument = delivery.collectorDocument || "";
        delivery.collectorName = delivery.collectorName || "";

        delivery._type = "Entrega";
        delivery.date = delivery.created_at;

        const defaultItens = {
          customerReferenceId: delivery.customerReferenceId,
          address: delivery.locker.address,
          compartmentNumber: delivery.compartment.compartmentNumber
        };

        Object.assign(delivery, {
          ...defaultItens,
          description: "-"
        });

        dataset.push(delivery); // add delivery Sheet(Geral)
        deliveries.push(delivery); // separa delivery para Sheet(Locacoes)

        delivery.compartmentOpening.map(item => {
          // standard itens
          Object.assign(item, {
            ...defaultItens,
            description: "-"
          });

          item.user = item.business.name;
          item._type = "Abertura de porta";
          item.date = item.createdAt;
          item.secretCode = delivery.secretCode;

          dataset.push(item);
          opening.push(item);
          return item;
        });

        delivery.supplies.map(item => {
          Object.assign(item, defaultItens);
          Object.assign(item, {
            user: item.business.name,
            _type: item.type.description,
            date: item.created_at,
            secretCode: delivery.secretCode
          });

          dataset.push(item);
          supplies.push(item);
          return item;
        });

        delivery.updateInfo.map(item => {
          Object.assign(item, {
            ...defaultItens,
            description: "-"
          });

          item.user = item.business.name;
          item._type = item.type.description;
          item.date = item.createdAt;
          item.secretCode = delivery.secretCode;

          dataset.push(item);
          updates.push(item);
          return item;
        });

        return delivery;
      } catch (mapError) {
        console.log("error on MAP");
        console.log(mapError);
      }
    });
  } catch (err) {
    console.log(err);
  }

  return { dataset, opening, updates, supplies, deliveries };
}

function handleOthersSupplies(data = []) {
  let dataset = [];

  try {
    data.map(item => {
      Object.assign(item, {
        user: item.business.name,
        _type: item.type.description,
        date: item.created_at
      });

      if (item.delivery) {
        Object.assign(item, {
          secretCode: item.delivery.secretCode,
          address: item.delivery.locker.address,
          compartmentNumber: item.delivery.compartment.compartmentNumber,
          customerReferenceId: item.delivery.customerReferenceId
        });
      }

      dataset.push(item);

      return item;
    });
  } catch (err) {
    console.log(err);
  } finally {
    return dataset;
  }
}

function handleOthersCompartmentSupplies(data = []) {
  let dataset = [];

  try {
    data.map(item => {
      Object.assign(item, {
        user: item.business.name,
        _type: "Abertura de porta",
        date: item.createdAt,
        description: "-"
      });

      if (item.delivery) {
        Object.assign(item, {
          secretCode: item.delivery.secretCode,
          address: item.delivery.locker.address,
          compartmentNumber: item.delivery.compartment.compartmentNumber,
          customerReferenceId: item.delivery.customerReferenceId
        });
      }

      dataset.push(item);

      return item;
    });
  } catch (err) {
    console.log(err);
  } finally {
    return dataset;
  }
}

export function ExportExcelExtractReports({
  data,
  classes,
  textSecretCode,
  othersSupplies = [],
  othersCompartmentOpening = []
}) {
  const { t } = useTranslation("reports");

  const reports = useSelector(state => state.reports);
  let _dataset = [];
  let _opening = [];
  let _supplies = [];
  let _updates = [];
  let _deliveries = [];

  if (othersSupplies.length) {
    const _set = handleOthersSupplies(othersSupplies);
    _dataset = _set;
    _supplies = _set;
  }

  if (othersCompartmentOpening.length) {
    const _set = handleOthersCompartmentSupplies(othersCompartmentOpening);
    _dataset = [..._dataset, ..._set];
    _supplies = _set;
  }

  if (reports.deliveries.data.length) {
    // locacoes em geral com seus registros abaixo
    const {
      dataset,
      opening,
      supplies,
      updates,
      deliveries
    } = getDeliveriesWithAllFields(reports.deliveries.data);
    _dataset = sortArrayObjectByDate([..._dataset, ...dataset]);

    // separa todas as aberturas de porta
    _opening = sortArrayObjectByDate([..._opening, ...opening]);

    // separa todos os registros de itens
    _supplies = sortArrayObjectByDate([..._supplies, ...supplies]);

    // separa todos os updates de delivery
    _updates = sortArrayObjectByDate([..._updates, ...updates]);

    // separa somente as locacoes
    _deliveries = sortArrayObjectByDate([..._deliveries, ...deliveries]);
  }

  return (
    <ExcelFile
      element={
        <Button
          className={classes.button}
          disabled={!data || !data.length}
          variant="contained"
          margin="normal"
          style={{
            backgroundColor: "#006660",
            color: "#fff",
            textTransform: "lowercase",
            fontSize: 14,
            height: 35
          }}
        >
          { t("filters.export")}
        </Button>
      }
    >
      {/* SHEET todos os registros unidos */}
      <ExcelSheet data={_dataset} name="Relatório geral completo">
        <ExcelColumn label="Entrega" value="customerReferenceId" />
        <ExcelColumn label={textSecretCode} value="secretCode" />
        <ExcelColumn label="ENDEREÇO" value="address" />
        <ExcelColumn label="PORTA" value="compartmentNumber" />
        <ExcelColumn label="TIPO DE OPERAÇÃO" value="_type" />
        <ExcelColumn label="USUÁRIO" value="user" />
        <ExcelColumn label="COLETADO POR" value="collectorType" />
        <ExcelColumn label="NOME" value="collectorName" />
        <ExcelColumn label="DOCUMENTO" value="collectorDocument" />
        <ExcelColumn label="DESCRIÇÃO" value="description" />
        <ExcelColumn label="DATA" value="date" />
      </ExcelSheet>

      {/* SHEET deliveries */}
      <ExcelSheet data={_deliveries} name="Locações">
        <ExcelColumn label="Entrega" value="customerReferenceId" />
        <ExcelColumn label={textSecretCode} value="secretCode" />
        <ExcelColumn label="ENDEREÇO" value="address" />
        <ExcelColumn label="PORTA" value="compartmentNumber" />
        <ExcelColumn label="USUÁRIO" value="user" />
        <ExcelColumn label="COLETADO POR" value="collectorType" />
        <ExcelColumn label="NOME" value="collectorName" />
        <ExcelColumn label="DOCUMENTO" value="collectorDocument" />
        <ExcelColumn label="DATA" value="date" />
      </ExcelSheet>

      {/* SHEET compartmentOpening */}
      <ExcelSheet data={_opening} name="Aberturas de portas">
        <ExcelColumn label="Entrega" value="customerReferenceId" />
        <ExcelColumn label={textSecretCode} value="secretCode" />
        <ExcelColumn label="PORTA" value="compartmentNumber" />
        <ExcelColumn label="USUÁRIO" value="user" />
        <ExcelColumn label="DATA" value="createdAt" />
      </ExcelSheet>

      {/* SHEET supplies */}
      <ExcelSheet data={_supplies} name="Registro de itens">
        <ExcelColumn label="Entrega" value="customerReferenceId" />
        <ExcelColumn label={textSecretCode} value="secretCode" />
        <ExcelColumn label="USUÁRIO" value="user" />
        <ExcelColumn label="TIPO DE OPERAÇÃO" value="_type" />
        <ExcelColumn label="DESCRIÇÃO" value="description" />
        <ExcelColumn label="DATA" value="created_at" />
      </ExcelSheet>

      {/* SHEET delivery updates */}
      <ExcelSheet data={_updates} name="Atualizações de status da entrega">
        <ExcelColumn label="Entrega" value="customerReferenceId" />
        <ExcelColumn label={textSecretCode} value="secretCode" />
        <ExcelColumn label="USUÁRIO" value="user" />
        <ExcelColumn label="TIPO DE OPERAÇÃO" value="_type" />
        <ExcelColumn label="DATA" value="createdAt" />
      </ExcelSheet>
    </ExcelFile>
  );
}

export function ExportExcelNepos({ data, classes }) {
  const dataset =
    data &&
    data.map(d => {
      d.address = `${d.locker.lockerNumber} - ${d.locker.address}`;
      return d;
    });
  return (
    <ExcelFile
      element={
        <Button
          className={classes.button}
          disabled={!data.length}
          variant="contained"
          margin="normal"
          style={{
            backgroundColor: "#006660",
            color: "#fff",
            textTransform: "lowercase",
            fontSize: 14,
            height: 35
          }}
        >
          Exportar para .xlsx
        </Button>
      }
    >
      <ExcelSheet data={dataset} name="Extrato de receitas">
        <ExcelColumn label="TICKET" value="ticket" />
        <ExcelColumn label="END. DO LOCKER" value="address" />
        <ExcelColumn label="DATA DE VALIDAÇÃO" value="created_at" />
      </ExcelSheet>
    </ExcelFile>
  );
}

export function ExportExcelFeedback({ data, classes }) {
  const dataset =
    data &&
    data.map(d => {
      d.address = `${d.locker.lockerNumber} - ${d.locker.address}`;
      d.feedback = JSON.parse(d.values).note;
      d.deliveryType = parseInt(d.idDeliveryType) === 1 ? "Entrega" : "Retorno";
      d.customerReferenceId = d.delivery.customerReferenceId;
      return d;
    });
  return (
    <ExcelFile
      element={
        <Button
          disabled={!data.length}
          className={classes.button}
          variant="contained"
          style={{
            backgroundColor: "#006660",
            color: "#fff",
            textTransform: "lowercase",
            fontSize: 14,
            height: 35
          }}
        >
          Exportar para .xlsx
        </Button>
      }
    >
      <ExcelSheet data={dataset} name="Extrato de receitas">
        <ExcelColumn label="DATA" value="created_at" />
        <ExcelColumn label="END. DO LOCKER" value="address" />
        <ExcelColumn label="EXPERIÊNCIA DO CLIENTE" value="feedback" />
        <ExcelColumn label="DESCRIÇÃO DO CLIENTE" value="customerDescription" />
        <ExcelColumn label="TIPO DE OPERAÇÃO" value="deliveryType" />
        <ExcelColumn label="ENTREGA" value="customerReferenceId" />
      </ExcelSheet>
    </ExcelFile>
  );
}

export function ExportExcelRental({ data, classes }) {
  let _dataset = [];

  _dataset = data.map(item => {
    const name = item.user ? item.user.name : "?";
    const surname = item.user ? item.user.surname : "?";

    const customItem = {
      cpf: item.user ? item.user.cpf : "?",
      phone: item.user ? item.user.phone : "?",
      email: item.user ? item.user.email : "?",
      fullname: `${name} ${surname}`,
      address: item.locker ? item.locker.address : "-",
      compartmentNumber: item.compartment
        ? item.compartment.compartmentNumber
        : "-",
      date: formatDate(item.created_at),
      finaldate: item.finishedAt ? formatDate(item.finishedAt) : "-",
      status: item.finishedAt ? "Finalizada" : "Ativa"
    };

    return customItem;
  });

  return (
    <ExcelFile
      element={
        <Button
          className={classes.button}
          disabled={!data || !data.length}
          variant="contained"
          margin="normal"
          style={{
            backgroundColor: "#006660",
            color: "#fff",
            textTransform: "lowercase",
            fontSize: 14,
            height: 35
          }}
        >
          Exportar para .xlsx
        </Button>
      }
    >
      {/* SHEET todos os registros unidos */}
      <ExcelSheet data={_dataset} name="Relatório geral completo">
        <ExcelColumn label="NOME DO USUARIO" value="fullname" />
        <ExcelColumn label="EMAIL DO USUARIO" value="email" />
        <ExcelColumn label="CPF DO USUARIO" value="cpf" />
        <ExcelColumn label="CONTATO DO USUARIO" value="phone" />

        <ExcelColumn label="LOCKER" value="address" />
        <ExcelColumn label="PORTA" value="compartmentNumber" />

        <ExcelColumn label="DATA" value="date" />
        <ExcelColumn label="DATA FINALIZADA" value="finaldate" />
        <ExcelColumn label="STATUS" value="status" />
      </ExcelSheet>
    </ExcelFile>
  );
}
