import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import '../../i18n';
import { Label, Selector } from './style';
import api from '../../services/api';
class LanguageSelector extends Component {
  handleChange = async event => {
    const { i18n } = this.props;
    const selectedLanguage = event.target.value;

    try {
      i18n.changeLanguage(selectedLanguage);

      await api.put('/v1/adm/language', {
        language: selectedLanguage,
      });
    } catch (error) {
      console.error('Erro ao salvar o idioma:', error);
    }
  };

  render() {
    const { t, i18n } = this.props;

    return (
      <Selector>
        <Label htmlFor="language-select">{t('language_selector:label')}</Label>
        <select
          id="language-select"
          onChange={this.handleChange}
          value={i18n.language}
        >
          <option value="en">English</option>
          <option value="pt-BR">Português</option>
        </select>
      </Selector>
    );
  }
}

export default withTranslation()(LanguageSelector);
