import React, { Component } from 'react';
import queryString from 'query-string';

// Material
import { TextField, MenuItem, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Global Components
import Container from '../../components/Container';
import SimpleActionButton from '../../components/SimpleActionButton';
import Spinner from '../../components/Spinner';

// Global Style
import { materialStyle } from '../../styles';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions as SubTeamActions } from '../../store/ducks/subTeam';
import { Actions as ConfigsActions } from '../../store/ducks/configs';
import { Actions as SubTeamUnitsActions } from '../../store/ducks/subTeamUnits';
import { Actions as SubTeamSubUnitsActions } from '../../store/ducks/subTeamSubUnits';
import { Actions as SubTeamSubUnitsContactActions } from '../../store/ducks/subTeamSubUnitsContact';
import { Actions as LocationActions } from '../../store/ducks/location';
import { withTranslation } from 'react-i18next';
// import Modal from "./components/ModalCreate";
import ModalContactCreate from './components/ModalContactCreate';
import ModalCreate from './components/ModalCreate';
import ModalCreateSubTeamSubUnits from '../subTeamUnitsEdit/components/ModalCreate';

import SubTeamSubUnitsContactTable from '../../components/SubTeamSubUnitsContactTable';

// history
import history from '../../routes/history';
import { constants } from '../../config';

class BusinessEdit extends Component {
  t = this.props.t;

  state = {
    data: [],
    columns: [{ title: this.t('common:block'), field: 'name' }],
    disabled: true,
    currentSubTeam: null,

    parameters: null,
    dashboardParams: null,
    name: '',
    email: '',
    status: 'all',
    idSubTeamUnits: 'all',
    idSubTeamSubUnits: 'all',
  };

  componentWillMount() {
    const {
      showSubTeamRequest,
      setToastVisible,
      getSubTeamSubUnitsContactRequest,
      getSubTeamUnitsRequest,
    } = this.props;
    const { id } = this.props.match.params;

    try {
      const parameters = JSON.parse(localStorage.getItem('auth')).currentTeam
        .parameters;

      if (parameters.dashboardParams && parameters.dashboardParams.length) {
        this.setState({
          dashboardParams: JSON.parse(parameters.dashboardParams),
        });
      }
    } catch (err) {}
    setToastVisible({ toastVisible: false });
    showSubTeamRequest(id);
    getSubTeamSubUnitsContactRequest(`idSubTeam=${id}`);
    getSubTeamUnitsRequest(`idSubTeam=${id}&perPage=1000`);
  }

  componentWillReceiveProps(nextProps) {
    const { subTeams, location } = nextProps;

    if (subTeams.currentSubTeam !== this.state.currentSubTeam) {
      this.setState({
        currentSubTeam: subTeams.currentSubTeam,
        snackbarVisible: subTeams.snackbarVisible,
      });
    }

    if (location.data && location.data.country) {
      this.setState({
        currentSubTeam: {
          ...this.state.currentSubTeam,
          idCountry: location.data.country.id,
          idState: location.data.state.id,
          idCity: location.data.city.id,
          streetName: location.data.streetName,
        },
      });
    }
  }

  handleChange = key => event => {
    const { getSubTeamSubUnitsRequest, resetSubTeamSubUnits } = this.props;
    let value = event.target.value;

    if (key === 'idSubTeamUnits' && value === 'all') {
      value = 'all';
      resetSubTeamSubUnits();
    } else if (key === 'idSubTeamUnits') {
      getSubTeamSubUnitsRequest(`idSubTeamUnits=${value}&perPage=1000`);
    }
    this.setState({
      [key]: value,
    });
  };

  handleSearch = () => {
    const { getSubTeamSubUnitsContactRequest, subTeams } = this.props;
    const {
      name,
      email,
      status,
      idSubTeamUnits,
      idSubTeamSubUnits,
    } = this.state;
    const query = queryString.stringify({
      name,
      email,
      idSubTeam: subTeams.currentSubTeam.id,
      idSubTeamUnits: idSubTeamUnits === 'all' ? '' : idSubTeamUnits,
      idSubTeamSubUnits: idSubTeamSubUnits === 'all' ? '' : idSubTeamSubUnits,
      status: status === 'all' ? '' : status,
    });

    getSubTeamSubUnitsContactRequest(query);
  };

  handleClear = () => {
    const { getSubTeamSubUnitsContactRequest, subTeams } = this.props;

    this.setState({
      email: '',
      name: '',
      idSubTeamUnits: 'all',
      idSubTeamSubUnits: 'all',
      status: 'all',
    });

    getSubTeamSubUnitsContactRequest(
      queryString.stringify({
        idSubTeam: subTeams.currentSubTeam.id,
      })
    );
  };

  renderContent = () => {
    const { classes, subTeams, subTeamSubUnits, subTeamUnits } = this.props;
    const {
      status,
      dashboardParams,
      name,
      email,
      idSubTeamUnits,
      idSubTeamSubUnits,
    } = this.state;

    return (
      <>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label={this.t('common:name')}
              className={classes.textField}
              value={name}
              onChange={this.handleChange('name')}
              margin="normal"
              variant="outlined"
              disabled={subTeams.loading}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label={this.t('common:email')}
              className={classes.textField}
              value={email}
              onChange={this.handleChange('email')}
              margin="normal"
              variant="outlined"
              disabled={subTeams.loading}
            />
          </Grid>

          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              disabled={subTeams.loading}
              id="custom-css-outlined-input"
              select
              label={this.t('common:status')}
              className={classes.selectType}
              value={status}
              onChange={this.handleChange('status')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
            >
              <MenuItem key={'all'} value={'all'}>
                {this.t('common:all')}
              </MenuItem>
              {[1, 0].map(item => (
                <MenuItem key={item} value={item}>
                  {item ? this.t('common:enabled') : this.t('common:disabled')}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              disabled={subTeams.loading}
              id="custom-css-outlined-input"
              select
              label={
                dashboardParams &&
                Object.keys(dashboardParams).includes('subTeamUnitsLabel')
                  ? dashboardParams.subTeamUnitsLabel
                  : this.t('common:block')
              }
              className={classes.selectType}
              value={idSubTeamUnits}
              onChange={this.handleChange('idSubTeamUnits')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
            >
              <MenuItem value={'all'}>{this.t('common:all')}</MenuItem>
              {subTeamUnits.data.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              disabled={subTeamSubUnits.loading || !subTeamSubUnits.data.length}
              id="custom-css-outlined-input"
              select
              label={
                dashboardParams &&
                Object.keys(dashboardParams).includes('subTeamSubUnitsLabel')
                  ? dashboardParams.subTeamSubUnitsLabel
                  : this.t('common:department')
              }
              className={classes.selectType}
              value={idSubTeamSubUnits}
              onChange={this.handleChange('idSubTeamSubUnits')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
            >
              <MenuItem value={'all'}>{this.t('common:all')}</MenuItem>
              {subTeamSubUnits.data.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <ModalContactCreate
          t={this.t}
          classes={classes}
          open={this.props.setDialogCreateVisibleSubTeamSubUnitsContact}
          handleClose={() => {
            this.props.setDialogCreateVisibleSubTeamSubUnitsContact(false);
            this.handleClear();
          }}
          subTeamUnitsQuery={`idSubTeam=${
            this.props.match.params.id
          }&perPage=1000`}
        />
        <ModalCreate t={this.t} />
        <ModalCreateSubTeamSubUnits
          subTeamUnitsQuery={`idSubTeam=${
            this.props.match.params.id
          }&perPage=1000`}
          classes={classes}
        />
        <Grid container spacing={1}>
          <Button
            onClick={this.handleSearch}
            variant="contained"
            className={classes.button}
            disabled={subTeams.loading}
            style={constants.buttonDefaultStyle}
          >
            {this.t('common:search')}
          </Button>
          <Button
            onClick={this.handleClear}
            variant="contained"
            className={classes.button}
            disabled={subTeams.loading}
            style={constants.buttonDefaultStyle}
          >
            {this.t('common:clear')}
          </Button>
          <SimpleActionButton
            variant="contained"
            options={[
              `${this.t('common:create_a')} ${
                dashboardParams &&
                Object.keys(dashboardParams).includes(
                  'subTeamSubUnitsContactLabel'
                )
                  ? dashboardParams.subTeamSubUnitsContactLabel
                  : this.t('common:resident') //'morador'
              }`,
              `${this.t('common:create_a')} ${
                dashboardParams &&
                Object.keys(dashboardParams).includes('subTeamUnitsLabel')
                  ? dashboardParams.subTeamUnitsLabel
                  : this.t('common:block')
              }`,
              `${this.t('common:create_a')} ${
                dashboardParams &&
                Object.keys(dashboardParams).includes('subTeamSubUnitsLabel')
                  ? dashboardParams.subTeamSubUnitsLabel
                  : this.t('common:apartment')
              }`,
              `${this.t('common:create_a')} ${this.t('data_batch')}`,
              `${this.t('common:view')} ${
                dashboardParams &&
                Object.keys(dashboardParams).includes('subTeamUnitsLabel')
                  ? dashboardParams.subTeamUnitsLabel
                  : this.t('common:block')
              }s`,
            ]}
            actions={[
              () =>
                this.props.setDialogCreateVisibleSubTeamSubUnitsContact(true),

              () => {
                this.props.setDialogCreateVisible(true);
              },
              () => {
                this.props.setDialogCreateVisibleSubTeamSubUnits(true);
              },
              () => {
                history.push(
                  `/subTeam/wizard?idSubTeam=${this.props.match.params.id}`
                );
              },
              () => {
                history.push(`/subTeamUnits/${this.props.match.params.id}`);
              },
            ]}
            label={`${this.t('common:create_a')} ${
              dashboardParams &&
              Object.keys(dashboardParams).includes(
                'subTeamSubUnitsContactLabel'
              )
                ? dashboardParams.subTeamSubUnitsContactLabel
                : this.t('common:resident') //'morador'
            }`}
          />
        </Grid>

        <SubTeamSubUnitsContactTable history={history} />
      </>
    );
  };

  render() {
    const { subTeamSubUnitsContact } = this.props;
    const { currentSubTeam } = this.state;

    return (
      <Container title="">
        {subTeamSubUnitsContact.loading ? (
          <Spinner />
        ) : (
          <>{currentSubTeam && this.renderContent()}</>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ...state,
});

const mapDispathToProps = dispatch =>
  bindActionCreators(
    {
      ...SubTeamActions,
      ...ConfigsActions,
      ...LocationActions,
      ...SubTeamUnitsActions,
      ...SubTeamSubUnitsActions,
      ...SubTeamSubUnitsContactActions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withTranslation('condominium')(withStyles(materialStyle)(BusinessEdit)));
