import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";

import SimpleActionButton from "../SimpleActionButton";
import defaultConfig from "../../config/default";
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

class subTeamsPaginationTable extends Component {
  t = this.props.t
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 5
  };

  handleChangePage = (event, newPage) => {
    const { getSubTeamUnitsRequest, subTeam } = this.props;

    const query = `page=${newPage + 1}&perPage=${parseInt(
      this.state.rowsPerPage
    )}&idSubTeam=${subTeam}`;

    getSubTeamUnitsRequest(query);
  };

  handleChangeRowsPerPage = event => {
    const { getSubTeamUnitsRequest, subTeam } = this.props;

    getSubTeamUnitsRequest(
      `perPage=${parseInt(event.target.value)}&idSubTeam=${subTeam}`
    );

    this.setState({ rowsPerPage: parseInt(event.target.value) });
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));

  render() {
    const classes = this.useStyles2();
    const { history, subTeamSubUnits, dashboardParams } = this.props;
    const { emptyRows } = this.state;
    const subTeamSubUnitsLabel =
      dashboardParams &&
      Object.keys(dashboardParams).includes("subTeamSubUnitsLabel")
        ? String(dashboardParams.subTeamSubUnitsLabel)
        : this.t('common:apartment');

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className="tableheader">
              <TableRow align="center">
                <TableCell
                  className="tablecell-header__initial-table"
                  align="center"
                  style={{ width: "50%", fontWeight: "bold" }}
                >
                  {subTeamSubUnitsLabel.toLowerCase()}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", marginLeft: -20 }}
                >
                  { this.t('common:actions')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tbody-business-simple-table">
              {subTeamSubUnits.data.map(row => (
                <TableRow key={row.id}>
                  <TableCell
                    className="tablecell-header__initial-table"
                    align="center"
                    style={{ width: "50%", fontSize: 14 }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell id="table-sm__svg" align="center">
                    <SimpleActionButton
                      label={this.t('common:edit')}
                      id={Math.random()}
                      actions={[
                        () => history.push(`/subTeamSubUnits/edit/${row.id}`)
                      ]}
                      options={[this.t('common:edit')]}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter className="table-footer" align="right">
              <TableRow align="right">
                <TablePagination
                  align="right"
                  rowsPerPageOptions={defaultConfig.rowsPerPageOptions}
                  colSpan={3}
                  count={subTeamSubUnits.total}
                  rowsPerPage={subTeamSubUnits.perPage}
                  page={subTeamSubUnits.page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true
                  }}
                  labelRowsPerPage={this.t("common:lines_per_page")}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to === -1 ? count : to} ${this.t("common:of")} ${count}`
                  }
                      />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  subTeamSubUnits: state.subTeamSubUnits,
  subTeamUnits: state.subTeamUnits
});

export default connect(mapStateToProps)(withTranslation('subteam')(subTeamsPaginationTable));
