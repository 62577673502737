import business from "../store/ducks/business";

export default {
  appbar: {
    my_profile: 'Meu perfil',
    create_delivery: 'criar entrega',
    logout: 'Sair',
  },
  menu_list_items: {
    manage_deliveries: 'gerenciar entregas',
    reports: 'relatórios',
    laundry_items: 'itens lavanderia',
    laundry_clients: 'clientes da lavanderia',
    locker_users: 'usuários guarda-volumes',
    lockers: 'lockers',
    lockers_management: 'gestão de lockers interno',
    rental: 'relatórios',
    b2c: 'b2c',
    income_statement: 'extrato de receita',
    coupons_usage: 'uso de cupons',
    zap: 'uso zap',
    feedback: 'feedback dos clientes',
    tickets: 'validação de tickets',
    config: 'configurações',
    units: 'unidades',
    tools: 'ferramentas',
    knowledge_base: 'base de conhecimento',
    support: 'suporte via chat',
    business: 'empresa',
    people: 'colaboradores',
    integrations: 'integrações',
    tools_email: 'envio de emails',
  },
  delivery_status: {
    waiting_to_deposit: 'Aguardando depósito',
    waiting_to_collect: 'Aguardando coleta',
    finished: 'Finalizada',
    waiting_to_return: 'Aguardando retorno',
    returned_delivery: 'Pedido retornado',
    canceled: 'Cancelada',
    finished_by_manager: 'Finalizada pelo gestor',
  },
  table_actions: {
    view: 'visualizar',
    edit: 'editar',
    finish: 'finalizar',
  },
  login: {
    login_title: 'Acesso para Empresas',
    forgot_password: 'Esqueceu a senha?',
    password_field_label: 'Senha',
    error: 'email ou senha inválidos',
    submit: 'Entrar',
    updated_password: 'Senha alterada com sucesso!',
  },
  language_selector: {
    label: 'Selecione o Idioma',
  },
  forgot_password: {
    header: 'EMPRESAS',
    title: 'Redefinir a senha',
    subtitle:
      'Insira seu e-mail corporativo para receber as instruções e gerar uma nova senha.',
    success_message: 'Email de recuperação enviado para',
    fail_message: 'Desculpe, não foi possível enviar email para',
    dismiss_btn: 'Fechar',
    submit_btn: 'Enviar',
    captcha_message: 'Realize a validação: "Não sou um robô"',
    loading_message: 'Enviando...',
    field_placeholder: 'Digite o seu email',
  },
  home: {
    title: 'Dados de entregas',
    deliveries: 'Entregas neste mês',
    waiting_deposit: 'Aguardando depósito',
    every_month: 'todos os meses',
    waiting_collect: 'Aguardando coleta',
    waiting_to_finish: 'Aguardando finalização',
    finished: 'Finalizadas',
    performance_chart_title: 'Performance de entregas neste mês',
    performance_chart_label: 'total por dia',
  },
  delivery: {
    title: 'Utilize uma das opções abaixo para realizar a pesquisa',
    delivery_number: 'Número da entrega',
    client: 'Cliente',
    locker: 'Locker',
    port: 'Porta',
    status: 'Status',
    unit: 'Unidade',
    block: 'Bloco',
    apartment: 'Apartamento',
    resident: 'Morador',
    start_date: 'Data inicial',
    end_date: 'Data final',
    start_date_placeholder: 'Data inicial das entregas',
    end_date_placeholder: 'Data final das entregas',
    search: 'buscar',
    clear: 'limpar',
    expired: 'expirada',
    createTitle: "Criar uma entrega",
    deliveryInfo: 'Informações da entrega',
    orderNumber: "Número de pedido",
    accessCode: 'Código de acesso',
    establishment: "Estabelecimento",
    bloc: 'Bloco/Torre',
    clientName: 'Nome do cliente',
    clientLastName: 'Sobrenome do cliente',
    clientNumber: 'Número do cliente',
    clientEmail: 'Email do cliente',
    doorSize: "Tamanho da porta",
    returnQuestion: "Entrega para retornar produto?",
    depositee: "Quem faz depósito",
    taker: "Quem faz coleta",
    itemsDescriptionTitle: "Descrição de itens na porta",
    itemsDescription: "Descrição de itens",
    receivedDocument: "Documento transferido!",
    receivedDocumentError: "Não foi possível baixar documento",
    editTitle: "Editar entrega",
    urlToken: "Token de URL",
    createdAt: "Data de criação",
    receiverInfo: "Informações do destinatário",
    table: {
      id: 'entrega',
      user: 'usuário',
      createdAt: 'data de criação',
      lockerAddress: 'locker',
      unit: 'unidade',
      compartmentNumber: 'porta',
      status: 'status',
      client: 'cliente',
      finished: 'finalizada',
      secretCode: 'código de acesso',
      note: 'experiência do cliente',
      remaining_time: 'tempo restante de coleta',
      actions: 'ações',
      subTeam: 'unidade principal',
      subTeamCondominium: 'condomínio',
      subTeamUnitsCondominium: 'bloco',
      subTeamSubUnitsCondominium: 'apartamento',
      subTeamSubUnitsContactCondominium: 'morador',
      lines_per_page: 'Linhas por página',
      of: 'de',
      dialogButton: 'Finalizar entrega',
      dialogTitle: 'Atenção',
      dialogMessage: 'Certifique-se de que todos os itens dentro da porta foram removidos.',
      dialogSubMessage: 'A notificação de finalização será enviado por email e/ou SMS do destinatário.',
      dialogFinalMessage: 'Deseja realmente finalizar a entrega?'
    },
    usageTable: {
      client: 'cliente',
      period: 'período',
      total: 'total'
    },
    view: {
      updates_tab: 'atualizações da entrega',
      ports_tab: 'aberturas de porta',
      items_tab: 'registros de itens',
      emails_tab: 'envio de emails',
      log_tab: 'Logs CORREIOS',
      item: 'item',
      quantity: 'quantidade',
    },
    updates: {
      user: 'USUÁRIO',
      date: 'DATA',
      type: 'TIPO',
      reason: 'motivo',
    },
    emails: {
      user: 'usuário',
      date: 'data',
      type: 'tipo do email',
      status: 'status',
    },
    logs: {
      delivery: 'entrega',
      code: 'código do log',
      description: 'descrição',
      block: 'bloco',
      apartment: 'apartamento',
      resident: 'morador',
      locker: 'locker',
      door: 'porta',
      date: 'data',
      openBlock: 'Abrir bloco',
    },
  },
  reports: {
    filters: {
      period: 'Período',
      select_locker: 'Escolha um locker',
      place: 'Estabelecimento',
      search: 'pesquisar',
      clear: 'limpar',
      export: 'exportar para .xlsx',
    },
    metrics: {
      total: 'Total',
      waiting_to_deposit: 'Aguardando depósito',
      waiting_to_collect: 'Aguardando coleta',
      waiting_to_return: 'Aguardando retorno',
      returned_order: 'Pedido retornado',
      finished: 'Finalizada',
    },
    table: {
      delivery: 'entrega',
      access_code: 'código de acesso',
      user: 'usário',
      main_unit: 'unidade principal',
      created_at: 'data de criação',
      locker: 'locker',
      door: 'porta',
      status: 'status',
      lines_per_page: 'Linhas por página',
      of: 'de',
    },
  },
  lockers: {
    filters: {
      client: 'Cliente',
      locker: 'Locker',
      search: 'pesquisar',
      clear: 'limpar',
    },
    table: {
      address: 'endereço',
      client: 'cliente',
      status: 'status',
      last_login: 'última conexão',
      actions: 'ações',
      manage: 'gerenciar',
      edit: 'editar status de implantação',
    },
    manage: {
      info: 'informações do locker',
      doors: 'portas',
      log: 'log correios',
      manage: 'administrar',
      camera: 'câmera',
      alarm: 'alarme',
      open_all: 'Abrir Locker inteiro',
      open_all_title: 'Atenção',
      open_all_message: 'Deseja realmente abrir todas as portas deste Locker?',
      open_all_confirm: 'Sim',
      open_all_dismiss: 'Cancelar',
    },
    info: {
      general: 'informações gerais',
      address: 'Enredeço',
      contact: 'informações do contato no local de instalação do locker',
      name: 'Nome',
      phone: 'Telefone/Celular',
      hub: 'Hub',
      access: 'informações de acesso ao local',
      working_hours: 'Horário de funcionamento',
      access_place: 'Local access',
    },
    doors_table: {
      lid: 'lid',
      door: 'door',
      status: 'status',
      dimensions: 'dimensões',
      actions: 'ações',
      open_action: 'Abrir porta',
      edit_action: 'Editar',
      attention: 'Atenção',
      confirm: 'Abrir',
      confirm_message: 'Deseja realmente abrir a porta?',
      cancel: 'Cancelar',
    },
    edit: {
      attach_door: 'Vincular porta a um',
      door_number: 'Número da porta',
      door_size: 'Tamanho',
      door_availability: 'Disponibilidade',
      edit: 'Editar',
      save: 'Salvar',
      cancel: 'Cancelar',
      open: 'abrir porta',
    },
  },
  config: {
    business: {
      title: 'Informações da Empresa',
      address: 'Endereço',
      missing_info: 'Não informado',
      accountable_info: 'Dados do responsável',
      updatePwdError: 'Erro ao atualiza senha!',
      newPwd: "Nova senha",
      confirmPwd: "Confirmar senha",
      loading: "Carregando...",
      update:  "Atualizar",
    },
    staff: {
      email: 'email',
      document: 'cpf',
      search: 'pesquisa',
      clear: 'limpar pesquisa',
      add: 'adicionar colaborador',
      name: 'nome',
      store: 'estabelecimento',
      phone: 'Contato de celular',
      status: 'status',
      actions: 'ações',
      business: 'empresa',
      role: 'função',
      title: 'editar cadastro',
      rules_title: 'regras de uso no painel',
      rule: 'regras',
      change_password: 'alterar a senha',
      login_field: 'Campo utilizado para fazer login',
      login_info: 'Informações de login',
      access_place: 'Estabelecimento de acesso',
      type: 'Tipo',
      create_acction: 'Criar',
    },
    integrations: {
      active_integration: 'Integração ativada',
      custom_model: 'Modelo customizado',
      integrations_module: 'Módulos de integração para SmartLocker',
      create: 'Criar nova integração',
      integration_name: 'Nome da integração',
      contact_data: 'Dados de contato',
      dev_name: 'Nome do desenvolvedor',
      contact_label: 'Contato',
      email: 'Email',
      integration_data: 'Dados de integração',
      token: 'Token',
      api_functions: 'Funções da API',
      api_functions_opt1: 'Gestão dos moradores (criar, editar e desativar)',
      view_header_message: 'Acesso de api externa para api Handover',
      documentation: 'Documentação',
      remove_integration: 'excluir integração',
      remove_integration_confirmation: 'Deseja realmente excluir a integração',
      create_webhook: 'criar webhook',
      webhook_subtitle:
        'Preencha os dados abaixo para ativar a chamada do seu webhook',
      webhook_url: 'URL',
      webhook_method_label: 'Método HTTP',
      webhook_api_functions: 'Funções da API Handover (via webhook)',
      headers_key: 'Chave',
      headers_value: 'Valor',
      remove_header_message: 'Deseja excluir este endereço?',
      empty_headers_message: 'Nenhum cabeçalho adicionado',
    },
  },
  condominium: {
    data_batch: 'dados em massa',
  },

  subteamsubunitscontact: {
    create: {
      title: 'Criar um morador',
      name: 'Nome do morador',
    },
    edit: {
      name: 'Nome do morador',
      phone: 'Tefone do morador',
      slack_id: 'ID de membro do slack',
    },
  },
  data_list: {
    feature: 'Funcionalidade',
    instructions:
      'Esta ferramenta auxilia a criação de cados no formato de lista. Pode-se usar também um arquivo excel .xlsx para carregar vários registros de uma vez.',
    create: 'Criar lista de dados',
    total: 'Total',
    error: 'Um ou mais itens não puderam ser cadastrados. Verifique na tabela',
    slack_id: 'ID Slack',
    load_file_btn: 'Carregar arquivo XLSX',
  },
  modal: {
    create_resident_title: 'Criar um morador',
    create_resident_subtitle: 'Informe abaixo os dados do morador',
    create_subtitle: 'Informe abaixo as informações para criar apenas um',
    create_subteamsubunit_subtitle: 'Informe abaixo os dados do',
  },
  appRoles: {
    colectAccount: 'Conta para coleta',
    deliveryAccount: 'Conta para entrega',
    mixed: 'Mista'
  },

  common: {
    all: 'Todos',
    none: 'Nenhuma',
    permissions: "Permissões",
    locker: 'locker',
    multiemail_helper_text:
      'Digite o email e aperte ENTER ou espaço. Máximo 20 emails.',
    notification_email: 'E-mails para notificação.',
    contact_phone: 'Número de contato',
    max_emails_reached: 'Número máximo de 20 emails atingido',
    not_found_user: 'Usuário não encontrado',
    name: 'Nome',
    name_or_id: 'Nome ou identificador do',
    company_registry: 'Cnpj',
    email: 'email',
    phone: 'celular',
    optional: 'opcional',
    status: 'status',
    unit: 'Unidade',
    block: 'Bloco',
    department: 'Departamento',
    apartment: 'Apartamento',
    resident: 'Morador',
    subTeamSubUnitsContact: 'Destinatário',
    actions: 'ações',
    language_selector: 'Selecione o Idioma',
    lines_per_page: 'Linhas por página',
    of: 'de',
    view: 'visualizar',
    search: 'pesquisar',
    search_clear: 'limpar pesquisa',
    create_a: 'criar um',
    create: 'criar',
    save: 'salvar',
    update: 'atualizar',
    back: 'voltar',
    dismiss: 'cancelar',
    edit: 'editar',
    add: 'adicionar',
    remove: 'deletar',
    password: 'Senha',
    password_confirmation: 'Confirme a senha',
    invalid_email: 'Email inválido',
    coppied_message: 'Valor copiado!',
    enabled: 'ativo',
    disabled: 'inativo',
    enable: 'ativar',
    disable: 'inativar',
    next: 'próximo',
    loadingMsg: 'Carregando...',
    noInfo: 'Nenhuma informação adicionada',
    cellphone: 'celular',
    document: 'cpf',
    client: 'cliente',
    door: 'porta',
    hours: 'horas',
    date: 'data',
    finishedAt: 'finalizada em',
    user: 'usuário',
    description: 'descrição',
    total: 'total',
    updated: 'atualizado',
    zipcode: 'CEP',
    type: 'tipo',
    fillEveryField: "Preencha todos os campos",
    select: 'Selecione',
    info: 'Informações',
  },
  camera: {
    error: 'Não foi possível obter a imagem da câmera',
    viewCamera: 'Visualizar câmera',
    openCamera: 'Abrir Câmera',
    close: 'Fechar'
  },
  customCancelDelivery: {
    openDoor: 'Abrir porta',
    dismiss: 'não'
  },
  customerFeedbackTable: {
    bad: 'Ruim',
    good: 'Boa',
    great: 'Ótima',
    operationType: 'tipo de operação',
    delivery: 'entrega',
    deliveredStatus: 'Entrega',
    returnedStatus: 'Retorno',
    title: "feedback de clientes",
    startDate: 'data inicial',
    endDate: 'data final',
    feedback: 'avaliação'
  },
  establishment: {
    dialogTitle: 'Envio de link para cadastro dos estabelecimentos',
    dialogBody: 'Clique no botão abaixo e envie o link para o responsável do estabelecimento. Este link tem a duração de 72h e pode ser mandado para múltiplos estabelecimentos.',
    copyLinkMsg: 'Copiar link',
    copiedLinkMsg: 'Link copiado com sucesso'
  },
  password: {
    distinctPwd: 'Senhas estão diferentes!',
    wrongPwd: 'Senha errada! Verifique os seus dados',
    modalTitle: 'Alterar senha',
    rulesMsg: 'A sua senha deve conter:',
    rule1: 'No minimo 7 carácteres.',
    rule2: 'Um combinação de letras e números.',
    rule3: 'Ao menos uma letra maiúscula e outra minúscula.',
    oldPwd: "Senha antiga",
    newPwd: "Nova senha",
    confirmPwd: "Confirmar senha",
    changePwd: "Alterar a senha",
  },
  groups: {

  },
  laundry: {
    max: 'MAX POR PEDIDO',
    maxByOrder: 'Max Por Lavagem',
    registrationOrigin: 'origem do cadastro',
    registrationDate: 'DATA CADASTRO'
  },
  locker: {
    pwd: 'senha',
    creationDate: 'Data criação',
    expirationDate: 'Data expiração',
    createTitle: "Criar um locker",
    availability: "Disponibilidade",
    type: 'tipo',
    capacity: "Capacidade",
    infoTitle: 'Informações do sistema do locker',
    url: 'url do locker',
    email: 'email do locker',
    password: 'senha do locker',
    cameraUrl: 'url da câmera',
    cameraEmail: 'email da câmera',
    cameraPassword: 'senha da câmera',
    contactName: 'nome do contato',
    contactPhone: 'telefone do contato',
    contactEmail: 'email do contato',
    editTitle: "Editar Locker",
    createdAt: 'Criado em',
    updatedAt: 'Última atualização'
  },
  order: {
    renter: 'locador',
    startDate: 'data início'

  },
  team: {
    doorOpen: 'ABER. PORTAS',
    deliveries: 'ENTREGAS',
    timeLimit: "Tempo limite para retirar produtos",
    timeInHours: "Tempo em horas",
    adminEmail: "Email no gestor",
    integrations: 'Integrações',
    noLimit: "0 = SEM LIMITE de tempo",
    timeLimitLocation: "Tempo limite de locacao em status de entrega",
    generalParams: 'Parâmetros gerais',
    admin: 'Responsável',
    city: 'Cidade',
    state: 'Estado',
    country: 'País',
    address: 'Endereço',
    addressLine1: "Logradouro",
    addressLine2: "Complemento",
    searchZip: "Buscar CEP",
    number: "Número",
    formalName: "Razão Social",
    brandName: "Nome fantasia",
    contract: 'Contrato'
  },
  spending: {
    initialDate: 'data inicial',
    rentalTime: 'tempo alugado',
    bonus: 'bônus usado',
    wallet: 'carteira usada',
    coupons: 'cupom usado',
    value: 'valor usado da carteira',
    debit: 'valor devedor pelo cliente',
    fee: 'taxa',
    income: 'receita',
  },
  credit: {
    value: 'valor',
    coupom: 'cupom',
  },
  errors: {
    zipcodeNotFound: "CEP não encontrado",
    userNotFound: 'Usuário não encontrado',
    emptyField: "Campos inválidos ou vazios!",
    error: "Erro",
    invalidPhone: "Número de celular inválido",
    invalidAccessCode: "Código de acesso inválido",
    invalidEmail: "Formato do email inválido"
  },
  thing: {
    createThingType: "Criar thing type",
    thingsType: "Tipos de things",
    yes: 'sim',
    no: 'não',
    occupied: 'em uso',
    free: 'livre'
  },
  roles: {
    createTitle: "Criar uma regra de perfil",
    ruleName: "Nome da regra",
    createBtn: "Criar nova regra",
    editTitle: "Editar regra de perfil",
    listTitle: "Regras atuais",
    newRule: "Nova regra",
    rules: 'Regras',
    admin: 'Administrativo'
  },
  business: {
    usersTitle: "Usuários Corporativos",
    createUser: "Criar usuário",
  },
  compartment: {
    createTitle: "Criar porta",
    doorNumber: 'número da porta',
    dimensions: "Dimensões",
    editTitle: 'Editar porta'
  },
  notFound: {
    title: 'página não encontrada',
    btn: 'voltar para home'
  },
  profile: {
    title: 'Alterar Perfil',
  },
  subteamssubunit: {
    editMessage: 'Informe abaixo os dados atualizados',
    changestatusMessage: 'Deseja realmente',
    named: 'com o nome'
  },
  signup: {
    mainTitle: 'Crie seu acesso para a handover empresas',
    sideTitle: 'Crie seu acesso para a Plataforma de gestão para empresas da handover',
    title: 'Insira alguns dados da sua empresa',
    companyName: "Nome da sua empresa",
    brandName: "Nome fantasia",
    email: "Endereço de e-mail corporativo",
    emailWarning: "O e-mail deve ser preferencialmente corporativo, e-mail pessoal não deve ser utilizado.",
    neighborhood: 'Bairro',
    street: 'Logradouro',
    addressComplement: 'Complemento',
    useTerm: 'Termos de Uso',
    cookies: 'Política de Cookies',
    privacy: 'Política de Privacidade',
    step2Title: 'Insira seus dados de gestor da operação',
    completeName: 'Nome completo',
    managerDocument: 'CPF do gestor',
    managerBirthdayDate: 'Data de nascimento',
    managerRequirement: 'Você precisa ser maior de 18 anos',
    step3Title: 'dados de acesso a conta',
    managerEmail: 'email do gestor',
    emailDescription: 'Digite o e-mail para acesso a conta.',
    passwordInstruction: 'Sua senha deve ter ao menos 8 dígitos',
    checkFields: 'Verifique se todos os campos estão corretos!',
    humanCheck: 'Não se esqueça de comfirmar a sua humanidade.',
    successMsg: 'Sua empresa foi cadastrada com sucesso.',
    successMsg2: 'Em até 48 horas você receberá um e-mail informando que o seu acesso foi liberado.',
    prev: 'Anterior',
    finish: 'concluir'
  }


};
